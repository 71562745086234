import { Check, Plus, X } from "lucide-react";
import { useState } from "react";

interface IProps {
  text: string;
  onSubmit: (value: string) => void;
}

export const AddButton = ({ text, onSubmit }: IProps) => {
  const [triggered, setTriggered] = useState(false);
  const [value, setValue] = useState("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(value);
    setValue("");
    setTriggered(false);
  };

  if (!triggered) {
    return (
      <button
        onClick={() => setTriggered(true)}
        className="flex w-fit items-center gap-4"
      >
        <Plus size={24} className="text-custom-grey" />
        <span className="font-bold text-custom-grey">{text}</span>
      </button>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="flex gap-4">
      <input
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className="h-6 w-40 rounded-md border border-gray-300 p-2"
        placeholder="Enter a name"
        maxLength={64}
        required
      />
      <div className="flex gap-2">
        <button
          type="submit"
          className="rounded-md bg-blue-500 p-1 text-white hover:bg-blue-600"
        >
          <Check size={14} />
        </button>
        <button
          onClick={() => {
            setTriggered(false);
            setValue("");
          }}
          className="rounded-md bg-red-500 p-1 text-white hover:bg-red-600"
        >
          <X size={14} />
        </button>
      </div>
    </form>
  );
};
