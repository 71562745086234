import { Baby } from "lucide-react";
import { ParentTree } from "../ParentTree";

export const Main = () => {
  return (
    <div className="flex min-h-screen flex-col p-16">
      <main className="flex-grow">
        <header className="flex items-center gap-8">
          <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-black shadow-[0px_4px_4px_rgba(0,0,0,0.25)]">
            <Baby size={28} className="text-white" />
          </div>
          <h1 className="text-[32px] font-black">Parental, Inc.</h1>
        </header>
        <section className="mt-8">
          <ParentTree />
        </section>
      </main>
      <footer>
        <p className="text-sm text-gray-500">
          Copyright © 2024 Matheus Costa. All Rights and Lefts Reserved.
        </p>
      </footer>
    </div>
  );
};
