import { Human } from "@app/models";
import { AddButton } from "../AddButton";
import { useState } from "react";
import { ChevronDown, ChevronRight } from "lucide-react";
import { useParents } from "../../hooks/useParents";
import { Loading } from "../Loading";

interface IProps {
  parent: Human;
}

export const ParentNode = ({ parent }: IProps) => {
  const { putHuman } = useParents();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const toggleExpand = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const handleAddChild = async (name: string) => {
    setIsCreating(true);
    await putHuman.mutateAsync({ name, parent: parent.id }).finally(() => {
      setIsCreating(false);
    });
  };

  return (
    <div>
      <button onClick={toggleExpand} className="flex items-center gap-4">
        {isExpanded ? (
          <ChevronDown size={24} className="text-black" />
        ) : (
          <ChevronRight size={24} className="text-black" />
        )}
        <span className="font-bold">{parent.name}</span>
      </button>

      {isExpanded && (
        <div className="flex flex-col gap-1 px-8 py-2">
          <AddButton text="Child" onSubmit={handleAddChild} />
          {parent.children?.map((child) => (
            <ParentNode key={child.id} parent={child} />
          ))}
          {isCreating && <Loading className="pl-10 pt-2" />}
        </div>
      )}
    </div>
  );
};
