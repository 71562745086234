import { useState } from "react";
import { useParents } from "../../hooks/useParents";
import { AddButton } from "../AddButton";
import { Loading } from "../Loading";
import { ParentNode } from "../ParentNode";

export const ParentTree = () => {
  const { parents, isLoadingParents, putHuman } = useParents();
  const [isCreating, setIsCreating] = useState(false);

  const handleAddParent = async (name: string) => {
    setIsCreating(true);
    putHuman.mutateAsync({ name }).finally(() => {
      setIsCreating(false);
    });
  };

  return (
    <div className="flex w-fit flex-col gap-1">
      <AddButton text="Parent" onSubmit={handleAddParent} />
      {isLoadingParents && <Loading className="pl-10 pt-2" />}
      {parents?.map((parent) => <ParentNode key={parent.id} parent={parent} />)}
      {isCreating && <Loading className="pl-10 pt-2" />}
    </div>
  );
};
