import { HumanSchema } from "@app/models";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

const parentsResponseSchema = z.array(HumanSchema);

const REACT_APP_API_URL =
  process.env.REACT_APP_API_URL || "http://localhost:3001";

export function useParents() {
  const queryClient = useQueryClient();

  const {
    data: parents,
    isLoading: isLoadingParents,
    error: parentsError,
  } = useQuery({
    queryKey: ["parents"],
    queryFn: async () => {
      const response = await fetch(`${REACT_APP_API_URL}/parents`);
      return parentsResponseSchema.parse(await response.json());
    },
  });

  const putHuman = useMutation({
    mutationFn: async (human: { name: string; parent?: string }) => {
      const response = await fetch(`${REACT_APP_API_URL}/human`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(human),
      });
      return HumanSchema.parse(await response.json());
    },
    onSuccess: (data) => {
      // Invalidate the parents query to refetch the data
      queryClient.invalidateQueries({ queryKey: ["parents"] });
    },
  });

  return { parents, isLoadingParents, parentsError, putHuman };
}
